$red: #EE3224;


@font-face {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 100;
    src: url("../webfonts/BebasRegular/BebasRegular.eot?#iefix") format("embedded-opentype"), 
    		 url("../webfonts/BebasRegular/BebasRegular.woff") format("woff"), 
    		 url("../webfonts/BebasRegular/BebasRegular.ttf") format("truetype")
}
@font-face {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 200;
    src: url("../webfonts/BebasRegular/BebasRegular.eot?#iefix") format("embedded-opentype"), 
    		 url("../webfonts/BebasRegular/BebasRegular.woff") format("woff"), 
    		 url("../webfonts/BebasRegular/BebasRegular.ttf") format("truetype")
}
@font-face {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 300;
    src: url("../webfonts/BebasRegular/BebasRegular.eot?#iefix") format("embedded-opentype"), 
    		 url("../webfonts/BebasRegular/BebasRegular.woff") format("woff"), 
    		 url("../webfonts/BebasRegular/BebasRegular.ttf") format("truetype")
}
@font-face {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 400;
    src: url("../webfonts/BebasRegular/BebasRegular.eot?#iefix") format("embedded-opentype"), 
    		 url("../webfonts/BebasRegular/BebasRegular.woff") format("woff"), 
    		 url("../webfonts/BebasRegular/BebasRegular.ttf") format("truetype")
}
@font-face {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 500;
    src: url("../webfonts/BebasRegular/BebasRegular.eot?#iefix") format("embedded-opentype"), 
    		 url("../webfonts/BebasRegular/BebasRegular.woff") format("woff"), 
    		 url("../webfonts/BebasRegular/BebasRegular.ttf") format("truetype")
}

@font-face {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 600;
    src: url("../webfonts/BebasBold/BebasBold.eot?#iefix") format("embedded-opentype"), 
    		 url("../webfonts/BebasBold/BebasBold.woff") format("woff"), 
    		 url("../webfonts/BebasBold/BebasBold.ttf") format("truetype")
}

@font-face {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 700;
    src: url("../webfonts/BebasBold/BebasBold.eot?#iefix") format("embedded-opentype"), 
    		 url("../webfonts/BebasBold/BebasBold.woff") format("woff"), 
    		 url("../webfonts/BebasBold/BebasBold.ttf") format("truetype")
}

@font-face {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 800;
    src: url("../webfonts/BebasBold/BebasBold.eot?#iefix") format("embedded-opentype"), 
    		 url("../webfonts/BebasBold/BebasBold.woff") format("woff"), 
    		 url("../webfonts/BebasBold/BebasBold.ttf") format("truetype")
}

@font-face {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 900;
    src: url("../webfonts/BebasBold/BebasBold.eot?#iefix") format("embedded-opentype"), 
    		 url("../webfonts/BebasBold/BebasBold.woff") format("woff"), 
    		 url("../webfonts/BebasBold/BebasBold.ttf") format("truetype")
}

@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,700&display=swap&subset=cyrillic');

* {
	box-sizing: border-box;
	border-collapse: collapse;
	padding: 0;
	margin: 0;
	outline: none;
	font-family: 'IBM Plex Sans', sans-serif;
}

.btn {
	border-radius: 50px;
	padding: 0 50px;
	border: none !important;
	box-shadow: none;
	&-primary {
		line-height: 60px;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: .05em;
		color: #fff;
		background: $red;
		font-weight: 600;
		&:hover {
			background: #C9362B;
			box-shadow: 0px 10px 40px -10px rgba(0,0,0,0.5);
		}
		&:active {
			background: $red;
			box-shadow: 0px 10px 40px -10px rgba(0,0,0,0.5);
			color: #fff !important;
		}
		&:focus {
			background: $red;
			box-shadow: 0px 10px 40px -10px rgba(0,0,0,0.5);
			color: #fff !important;
		}
	}
	&-secondary {
		line-height: 58px;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: .05em;
		color: $red;
		border: 1px solid $red !important;
		background: transparent;
		font-weight: 600;
		&:hover {
			background: $red;
			color: #fff;
			box-shadow: 0px 10px 40px -10px rgba(0,0,0,0.5);
		}
		&:active {
			background: $red;
			color: #fff;
			box-shadow: 0px 10px 40px -10px rgba(0,0,0,0.5);
		}
		&:focus {
			background: $red;
			color: #fff;
			box-shadow: 0px 10px 40px -10px rgba(0,0,0,0.5);
		}
	}
	&-outline-primary {
		line-height: 42px;
		font-size: 14px;
		padding: 0 20px;
		text-transform: uppercase;
		letter-spacing: .05em;
		color: #fff;
		background: transparent;
		font-weight: 600;
		border: 2px solid #fff!important;
		&:hover {
			background: $red;
			border-color: $red !important;
			box-shadow: 0px 10px 40px -10px rgba(0,0,0,0.5);
		}
		&:active {
			background: $red;
			border-color: $red !important;
			box-shadow: 0px 10px 40px -10px rgba(0,0,0,0.5);
			color: #fff;
		}
		&:focus {
			box-shadow: none;
			border-color: #fff;
			color: #fff;
		}
	}
	&-secondary {}
}

.container-padding {
	padding: 0 30px;
}

a:hover {
	text-decoration: none;
}

main .app {
	background: #0077c0 url('../images/app-bg.jpg') center / contain no-repeat;
	padding-top: 30px !important;
	.breadcrumbs {
		margin-bottom: 135px;
		a, span {
			color: #fff;
		}
		a:before {
			filter: brightness(100);
		}
	}
	p, h1 {
		color: #fff !important;
	}
	p {
		font-size: 20px;
		line-height: 1.5;
		margin: 0 0 30px;
		& + ul {
			margin-top: 60px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;
			list-style: none;
			padding: 0;
			li {
				margin-right: 20px;
			}	
		}
	}
}

header {
	background-size: cover !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
	&.main {
		height: 100vh;
		min-height: 770px;
		.header {
			height: 100%;
			.before-left {
				height: 100%;
				&:before {
					bottom: 0;
				}
			}
		}
	}
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 7;
		background: rgba(0,0,0,.4);
		display: none;
	}
	.container-padding {
		padding: 30px 30px;
		position: relative;
		z-index: 9;
	}
	.logo {
		margin-right: 50px;
		a {}
	}
	.city {
		select {
			background: transparent;
			border: none;
			font-size: 12px;
			color: #fff;
			width: 120px;
			cursor: pointer;
			position: relative;
			z-index: 2;
		}
		option {
			color: #000 !important;
		}
	}
	ul {
		margin: 0;
		padding: 0;
		li {
			margin: 0 17px;
			position: relative;
			line-height: 48px;
			ul {
				position: absolute;
				display: none;
				list-style: none;
				padding: 0;
				margin: 0;
				left: 0;
				top: 46px;
				&:hover {
					display: inline-block;
				}
				li {
					padding: 0 0 10px;
					margin: 0;
					line-height: 1.5;
				}
				a {
					white-space: nowrap;
				}
			}
			&:hover {
				ul {
					display: inline-block;
				}
			}
		}
		a {
			font-weight: bold;
			font-size: 14px;
			text-transform: uppercase;
			color: #fff !important;
			padding-bottom: 5px;
			position: relative;
			&:before {
				content: '';
				width: 0;
				height: 2px;
				background: $red;
				transition: all .3s linear;
				left: 0;
				bottom: 0;
				display: inline-block;
				position: absolute;
			}
			&:hover {
				&:before {
					width: 100%;
				}
				color: #fff;
			}
		}
	}
	.phone {
		margin-right: 40px;
		a {
			font-family: Bebas Neue;
			color: #fff;
			border-bottom: 1px solid rgba(255,255,255,.2);
			font-weight: bold;
			font-size: 28px;
			&:hover {
				border-color: $red;
				text-decoration: none;
				color: $red;
			}
		}
	}
	.btn {}
	.lang {
		margin-left: 75px;
		a {
			font-weight: bold;
			font-size: 12px;
			line-height: 15px;
			text-align: center;
			text-transform: uppercase;
			color: #FFFFFF;
			opacity: .5;
			margin-left: 15px;
			&.active {
				opacity: 1;
			}
			&:hover {
				color: $red;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
}

.header {
	padding: 110px 0 125px;
	position: relative;
	.before-left {
		&:before {
			bottom: -125px;
		}
	}
	h1 {
		font-family: Bebas Neue;
		font-weight: bold;
		font-size: 90px;
		line-height: 1;
		letter-spacing: 0.03em;
		color: #FFFFFF;
		margin-bottom: 20px;
	}
	p {
		font-size: 20px;
		line-height: 150%;
		letter-spacing: -0.005em;
		color: #FFFFFF;
		margin-bottom: 35px;
	}
	&__video {
		font-weight: bold;
		font-size: 15px;
		line-height: 1;
		text-align: center;
		text-transform: uppercase;
		color: #FFFFFF;
		background: url('../images/play.svg') top center / 70px no-repeat;
		padding-top: 90px;
		display: inline-block;
		&:hover {
			color: $red;
			text-decoration: none;
		}
	}
	&-inner {
		padding-bottom: 110px;
		.before-center {
			&:before {
				bottom: -116px;
			}
		} 
	}
	&__slick {
		.slick-dots {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			li {
				width: 10px;
				height: 10px;
				margin: 20px 0;
				&.slick-active {
					width: 40px;
					height: 40px;
					button {
						width: 100%;
						height: 100%;
						background: url('../images/dots.png') center no-repeat;
					}
				}
				button {
					width: 10px;
					height: 10px;
					background: #fff;
					border-radius: 10px;
					&:before {
						display: none;
					}
				}
			}
		}
	}
}

.before {
	&-left {
		&:before {
			content: url('../images/scroll.svg');
			position: absolute;
			bottom: 0;
			left: 0;
			height: 61px;
		}
	}
	&-center {
		&:before {
			content: url('../images/scroll.svg');
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 61px;
		}
	}
	&-blue {
		&:before {
			content: url('../images/scroll-blue.svg');
		}
	}
}

.nav-pills {
	li {
		margin: 0 0 0 30px;
	}
	a {
		display: inline-block;
		line-height: 56px;
		border: 1px #212121 solid;
		padding: 0 40px;
		border-radius: 46px !important;
		font-weight: bold;
		font-size: 14px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #212121;
		.fa {
			margin-right: 10px;
			display: inline-block;
		}
		&.active {
			background: #212121 !important;
			color: #fff !important;
		}
		&:hover {
			background: #212121 !important;
			color: #fff !important;
		}
	}
}

.box-blue {
	.nav-pills {
		a {
			border: 1px #fff solid;
			color: #FFFFFF;
			&.active {
				background: #fff !important;
				color: #0077C0 !important;
			}
			&:hover {
				background: #fff !important;
				color: #0077C0 !important;
			}
		}
	}
	.mCSB_scrollTools .mCSB_draggerRail {
	    background-color: rgba(255,255,255,.3);
	}
	.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	    background-color: #fff !important;
	}
	.contacts {
		&-item {
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 1px solid rgba(255,255,255,.15);
			strong {
				font-family: Bebas Neue;
				font-weight: bold;
				font-size: 30px;
				line-height: 26px;
				color: #FFFFFF;
				display: inline-block;
				margin: 0 0 18px;
			}
			p {
				font-size: 13px;
				line-height: 20px;
				color: #FFFFFF;
				margin: 0 0 5px;
			}
			span {
				display: inline-block;
				margin-bottom: 10px;
				font-family: Bebas Neue;
				font-style: normal;
				font-weight: normal;
				font-size: 22px;
				line-height: 26px;
				letter-spacing: 0.03em;
				color: #212121;
				width: 100%;
			}
			a {
				font-family: Bebas Neue;
				font-style: normal;
				font-weight: normal;
				font-size: 22px;
				line-height: 26px;
				letter-spacing: 0.03em;
				color: #FFFFFF;
				&.modal__link {
					font-family: IBM Plex Sans;
					font-weight: bold;
					font-size: 14px;
					line-height: 18px;
					letter-spacing: 0.05em;
					text-transform: uppercase;
					color: #EE3224;
				}
			}
		}
	}
	.form.search {
		margin: 0 0 70px;
		form {
			position: relative;
			padding: 0;
			button {
				height: 56px;
				width: 56px;
				cursor: pointer;
				background: none;
				border: none;
				color: #fff !important;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		input {
			padding-right: 56px !important;
			color: #fff !important;
			border-bottom-color: #fff !important;
			&::-webkit-placeholder {
				color: #fff;
			}
			&::placeholder {
				color: #fff;
			}
		}
	}
}

.malihu {
	max-height: 340px;
	padding: 0 15px;
}

.box {
	padding: 150px 0;
	&-gray {
		padding: 100px 0;
		background: #f4f4f4;
	}
	&-blue {
		padding: 130px 0;
		background: #0077C0;
		color: #fff;
		.title {
			h3 {
				color: #fff;
			}
		}
	}
	&-404 {
		background: url('../images/err-bg.jpg') center / contain no-repeat;
		padding: 210px 0 250px;
		p {
			font-size: 20px;
			line-height: 1.5;
			letter-spacing: -.005em;
			color: #212121;
			margin: 30px 0 60px;
		}
		a:not([class]) {
			color: #0077C0;
			&:hover {
				color: $red;
				text-decoration: none;
			}
		}
	}
	&-sm {
		padding: 90px 0;
	}
}

.ovh {
	overflow: hidden;
}

.slick-services {
	width: 120%;
}

.advantages-item {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 80px;
	.image {
		margin-right: 25px;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 90px;
		height: 90px;
		border: 2.5px solid #212121;
		border-radius: 90px;
		flex: 0 0 90px;
		img {}
	}
	.text {
		line-height: 1;
		h4 {
			font-weight: bold;
			font-size: 22px;
			line-height: 150%;
			color: #212121;
			margin: 0 0 20px;
			& + p {
				font-size: 20px;
				line-height: 1.5;
			}
		}
		strong {
			font-family: Bebas Neue;
			font-weight: bold;
			font-size: 48px;
			line-height: 120%;
			text-transform: uppercase;
			color: #212121;
			display: inline-block;
		}
		p {
			margin: 0;
			font-size: 13px;
			line-height: 20px;
			color: #212121;
		}
	}
}

.title {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 50px;
	h1, .h1 {
		font-family: Bebas Neue;
		font-style: normal;
		font-weight: bold;
		font-size: 88px;
		line-height: 1;
		color: #212121;
		text-transform: uppercase;
		margin: 0;
	}
	h2, h3, .h2, .h3 {
		font-family: Bebas Neue;
		font-style: normal;
		font-weight: bold;
		font-size: 64px;
		line-height: 64px;
		color: #212121;
		text-transform: uppercase;
		margin: 0;
	}
	span {
		font-size: 20px;
		color: #212121;
		max-width: 45%;
	}
}

h2, .h2 {
	font-family: Bebas Neue;
	font-style: normal;
	font-weight: bold;
	font-size: 64px;
	line-height: 64px;
	color: #212121;
	text-transform: uppercase;
	margin: 0 0 30px;
}

h3, .h3 {
	font-family: Bebas Neue;
	font-style: normal;
	font-weight: bold;
	font-size: 54px;
	line-height: 54px;
	color: #212121;
	text-transform: uppercase;
	margin: 0 0 30px;
}

h4, .h4 {
	font-weight: bold;
	font-size: 22px;
	line-height: 150%;
	text-transform: uppercase;
	margin-bottom: 30px;
	color: #212121;
}

p {
	font-size: 16px;
	line-height: 26px;
	margin-bottom: 30px;
}

.bg_link {
	height: 300px;
	line-height: 300px;
	text-decoration: none !important;
	font-weight: bold;
	font-size: 54px;
	color: #212121;
	position: relative;
	& * {
		font-family: Bebas Neue;
	}
	.before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		transition: all 0.2s linear;
		opacity: 1;
	}
	&-ext {
		background: $red;
		transition: all 0.2s linear;
		&:hover {
			color: #fff;
		}
	}
	&-border {
		.row {
			border-bottom: 1px solid rgba(33,33,33,.15);
			position: relative;
			z-index: 9;
		}
	}
	.col-12 {
		background: url('../images/link.svg') right center no-repeat;
	}
	.col-5 {
		background: url('../images/link.svg') right center no-repeat;
	}
	span {
		font-weight: bold;
		font-size: 22px;
		line-height: 26px;
		text-transform: uppercase;
		color: #FFFFFF;
		padding: 0 65px;
		display: none;
		font-family: 'IBM Plex Sans', sans-serif;
	}
	&:hover {
		.row {
			border-bottom: none;
		}
		.col-5 {
			background: $red url('../images/link-w.svg') right center no-repeat;
		}
		.before {
			opacity: 0;
		}
	}
}

.link_social {
	&-xl {
		background-position: center;
		background-size: 105% 105%;
		background-repeat: no-repeat;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20%;
		color: #fff;
		flex: 0 0 20%;
		height: 380px;
		font-family: Bebas Neue;
		font-weight: bold;
		font-size: 54px;
		&:hover {
			color: $red;
		}
	}
}

.form {
	padding: 130px 0 100px;
	h3 {
		color: #fff;
	}
	p {
		font-size: 20px;
		line-height: 1.5;
		color: #fff;
	}
	&.search {
		margin: 0 0 70px;
		form {
			position: relative;
			padding: 0;
			button {
				height: 56px;
				width: 56px;
				cursor: pointer;
				background: none;
				border: none;
				color: #212121;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		input {
			padding-right: 56px !important;
			color: #212121 !important;
			border-bottom: 1px solid rgba(0,0,0,.15) !important;
			padding: 0 !important;
			&::-webkit-placeholder {
				color: #212121;
			}
			&::placeholder {
				color: #212121;
			}
		}
	}
	form {
		padding-top: 60px;
		.form {
			&-group {
				position: relative;
				label {
					position: absolute;
					left: 0;
					top: 40%;
					font-size: 16px;
					color: #fff;
					transition: all .3s linear;
					&.active {
						font-size: 11px;
						top: 15%;
						opacity: .5;
					}
				}
			}
			&-control {
				height: 56px;
				background: none;
				border-radius: 0;
				box-shadow: none !important;
				border: none;
				padding: 15px 0 0;
				font-weight: 500;
				color: #fff;
				border-bottom: 1px solid rgba(255,255,255,.5);
				&:focus {
					border-bottom-color: #fff;
					& + label {
						font-size: 11px;
						top: 15%;
						opacity: .5;
					}
				}
			}
			&-file {
				width: 100%;
				height: 56px;
				border-radius: 4px;
				border: 2px dashed rgba(255,255,255,.5);
				padding: 0 20px;
				&:hover {
					cursor: pointer;
					border-style: solid;
				}
				input {
					display: none;
				}
				p {
					background: url('../images/cloud.svg') right center no-repeat;
					line-height: 56px;
					font-size: 14px;
					color: #fff;
					opacity: 1;
					text-transform: uppercase;
					font-weight: 600;
					padding: 0 50px 0 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					cursor: pointer;
				}
			}
		}
		.btn {
			line-height: 56px;
			padding: 0 30px;
			margin-top: 60px;
			margin-bottom: 15px;
		}
		p {
			font-size: 13px;
			line-height: 20px;
			margin: 0;
			color: rgba(255,255,255,.5);
			a {
				color: rgba(255,255,255,.5);
				&:hover {
					color: $red;
				}
			}
		}
		select.form-control {
			margin: 0 0 30px;
			height: 56px !important;
			line-height: 56px;
			padding: 0 15px;
			border: 1px solid #fff;
			option {
				color: #212121;
			}
		}
	}
	&_blue {
		background: #0077C0 url('../images/form-ptn.png') center / contain no-repeat;
	}
	&_black {
		background: #212121 url('../images/form-ptn.png') center / contain no-repeat;
	}
}

.about {
	background: #212121 url('../images/about-bg.jpg') center / contain no-repeat;
	padding: 150px 0 80px;
	h2 {
		color: #fff;
	}
	p {
		margin-bottom: 30px;
		line-height: 1.5;
		font-size: 16px;
		color: #fff;
	}
	a {
		margin-bottom: 80px;
		margin-top: 20px;
	}
	.before-left {
		&:before {
			bottom: -80px;
		}
	}
	.counts-item {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		color: #fff;
		strong {
			font-family: Bebas Neue;
			font-weight: bold;
			font-size: 64px;
			line-height: 64px;
			color: #FFFFFF;
		}
		p {
			margin: 0 0 0 25px;
			font-size: 16px;
			line-height: 1;
			color: #fff;
		}
	}
}

.slick-news {
	.slick-list {
		margin: 0 -15px;
	}
	.news-item {
		margin: 0 15px;
	}
}

.tab-pane {
	.news-item a {
		&:before {
			display: none !important;
		}
		&:hover {
			span, p {
				color: $red !important;
			}
		}
	}
	.news-image {
		a {
			transition: background .2s linear;
			background-size: 100% !important;
		}
		a:hover {
			background-size: 110% !important;
			span, p {
				color: #fff !important;
			}
		}
	}
}

.news-item {
	margin: 0 0px 30px;
	a {
		display: inline-block;
		width: 100%;
		padding: 40px 30px 210px;
		text-decoration: none !important;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #fff;
		}
		&:hover {
			text-decoration: none !important;
			&:before {
				display: none;
			}
		}
		span {
			display: inline-block;
			width: 100%;
			font-family: Bebas Neue;
			margin-bottom: 20px;
			font-weight: bold;
			font-size: 28px;
			line-height: 28px;
			letter-spacing: 0.03em;
			color: $red;
			position: relative;
			z-index: 3;
		}
		p {
			font-size: 16px;
			line-height: 26px;
			color: #212121;
			margin: 0;
			position: relative;
			z-index: 3;
		}
	}
	&.news-image {
		span, p {
			color: #fff !important;
		}
	}
	&:hover {
		span, p {
			color: #fff !important;
		}
	}
	&-xl {
		span {
			font-family: Bebas Neue, Roboto, sans-serif;
			font-weight: bold;
			font-size: 28px;
			line-height: 31px;
			letter-spacing: 0.03em;
			color: #EE3224;
			margin-bottom: 30px;
			display: inline-block;
		}
		h2 {
			color: #212121;
			margin-bottom: 70px;
		}
		
		.r {
			font-weight: bold;
			font-size: 14px;
			line-height: 18px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #212121;
			background: url('../images/next.svg') right center no-repeat;
			padding-right: 30px;
			&:hover {
				color: $red;
			}
		}
	}
}

.slick-services {
	.slick-list {
		margin: 0 -15px 0 -15px;
	}
}

.services-item {
	margin: 0 15px;
	&:hover {
		.text {
			p, a {
				display: inline-block;
			}
		}
	}
	.image {
		width: 100%;
		height: 350px;
	}
	.text {
		background: #f2f2f2;
		padding: 30px 35px 40px;
		strong {
			display: inline-block;
			width: 100%;
			font-weight: bold;
			font-size: 22px;
			line-height: 120%;
			color: #212121;
		}
		p {
			font-size: 16px;
			line-height: 26px;
			color: #212121;
			// display: none;
			margin-top: 15px;
		}
		a {
			// display: none;
		}
	}
}

.padding-left {
	padding-left: calc((100% - 1110px) / 2);
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 50px;
	a {
		font-weight: bold;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		margin-right: 25px;
		color: #212121;
		&.prev {
			background: url('../images/prev.svg') left center no-repeat;
			padding-left: 25px;
			text-decoration: none !important;
		}
		&.next {
			background: url('../images/next.svg') right center no-repeat;
			padding-right: 25px;
			text-decoration: none !important;
		}
		&:hover {
			color: $red;
			text-decoration: none;
		}
	}
	.dots {
		margin-left: 25px;
		.slick-dots {
			position: static;
			width: auto;
			display: inline-block;
			li {
				button {
					width: 20px;
					height: 20px;
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;
				}
				button:before {
					font-size: 0px;
					width: 8px;
					height: 8px;
					border-radius: 8px;
					background: #212121;
					opacity: 1;
					position: static;
					display: inline-block;
				}
			}
			li.slick-active {
				button {
					border: 2px solid $red;
					border-radius: 20px;
					&:before {
						background: $red;
					}
				}
			}
		}
	}
}

.breadcrumbs {
	list-style: none;
	margin: 0;
	padding: 40px 0 0;
	margin-bottom: -20px;
	li {
		display: inline-block;
		margin-right: 40px;
		span {
			font-size: 14px;
			color: #212121;
			text-decoration: none !important;
		}
		a {
			font-size: 14px;
			color: #212121;
			text-decoration: none !important;
			position: relative;
			display: inline-block;
			&:before {
				content: url('../images/next.svg');
				position: absolute;
				right: -30px;
				top: 0;
			}
			&:hover {
				color: $red;
			}
		}
	}
}

.more {
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: #212121;
	background: url('../images/plus.svg') right center no-repeat;
	padding-right: 30px;
	margin-top: 30px;
	display: inline-block;
	&:hover {
		color: $red;
	}
}

.files-item {
	padding: 10px 80px 20px 20px;
	background: #f4f4f4;
	display: inline-block;
	width: 100%;
	position: relative;
	margin-bottom: 60px;
	overflow: hidden;
	img {
		margin-bottom: 10px;
	}
	p {
		color: #212121;
		font-size: 16px;
		line-height: 1.5;
		height: 80px;
		overflow: hidden;
		margin-bottom: 10px;
	}
	span {
		color: #212121;
		opacity: .5;
		font-size: 14px;
	}
	.d {
		position: absolute;
		z-index: 3;
		top: 50%;
		left: 50%;
		margin: -40px 0 0 -40px;
		display: none;
	}
	&:before {
		content: '';
		border: 50px solid transparent;
		border-bottom: 50px solid #dedede;
		position: absolute;
		bottom: 20px;
		right: 20px;
		transform: rotate(-45deg);
	}
	&:after {
		content: '';
		border: 50px solid transparent;
		border-bottom: 50px solid #fff;
		position: absolute;
		bottom: -50px;
		right: -50px;
		transform: rotate(135deg);
	}
	&:hover {
		background: #dcdcdc;
		img {
			filter: grayscale(1);
		}
		&:before {
			border-bottom: 50px solid #c8c8c8;
		}
		.d {
			display: inline-block;
			filter: none;
		}
	}
}

.tanders-item {
	margin-bottom: 70px;
	display: inline-block;
	width: 100%;
	&:hover {
		p:before {
			background: $red url('../images/link-w.svg') center no-repeat;
		}
	}
	.image {
		width: 100%;
		height: 280px;
	}
	p {
		margin: 0;
		position: relative;
		padding: 25px 90px 25px 0;
		font-size: 20px;
		line-height: 150%;
		letter-spacing: -0.005em;
		color: #212121;
		&:before {
			content: '';
			width: 68px;
			height: 68px;
			border-radius: 68px;
			border: 1px solid $red;
			background: url('../images/link.svg') center no-repeat;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -34px;
		}
	}
}

.command-item {
	width: 100%;
	position: relative;
	margin-bottom: 100px;
	display: inline-block;
	&:before {
		content: '';
		width: 160px;
		height: 2px;
		background: $red;
		position: absolute;
		bottom: 0;
		left: 0;
	}
	.image {
		width: 100%;
		height: 360px;
		margin-bottom: 50px;
	}
	h3 {
		margin: 0;
		font-size: 48px;
	}
	strong {
		display: inline-block;
		width: 100%;
		margin: 10px 0 40px;
		font-size: 14px;
		line-height: 18px;
		text-transform: uppercase;
		color: #212121;
	}
	p {
		margin-bottom: 30px;
		width: 80%;
		height: 120px;
		overflow: hidden;
	}
} 

.nav-tabs {
	margin-top: 30px;
	margin-bottom: 50px;
	border-bottom: 1px solid #212121;
	justify-content: space-between;
	.nav-item {
		border: none;
		padding: 0;
		margin: 0;
		.nav-link {
			border: none;
			padding: 0;
			margin: 0;
			font-weight: bold;
			font-size: 14px;
			line-height: 18px;
			letter-spacing: 0.05em;
			color: #212121;
			padding-bottom: 30px;
			text-transform: uppercase;
			background: transparent;
			&.active {
				color: $red;
				border: none;
				border-bottom: 3px solid $red;
			}
		}
	}
}

.command {
	background: url('../images/command-bg.jpg') center bottom / cover no-repeat;
	padding: 170px 0 60px;
	h2 {
		color: #fff;
		margin-bottom: 30px;
	}
	p {
		margin-bottom: 60px;
		color: #fff;
		font-size: 20px;
		line-height: 1.5;
	}
	a {
		margin-bottom: 120px;
		color: #fff;
	}
	.pagination {
		a {
			margin-bottom: 0;
			filter: brightness(10);
		}
		.dots {
			.slick-dots {
				li button:before {
					background: #fff;
				}
			}
		}
	}
}

.works-item {
	display: inline-block;
	padding: 30px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	height: 310px;
	text-decoration: none !important;
	color: #fff !important;
	margin-bottom: 30px;
	strong {
		font-family: Bebas Neue;
		font-style: normal;
		font-weight: bold;
		font-size: 64px;
		line-height: 64px;
		color: #FFFFFF;
		margin-right: 20px;
	}
	p {
		margin: 0;
		font-size: 20px;
		line-height: 1.3;
	}
}

.opt-wrapper {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	flex-wrap: nowrap;
	margin: 0 -15px 120px;
}

.opt-item {
	padding: 30px 30px 50px;
	background: #EE3224;
	flex: 0 1 25%;
	margin: 0 15px;
	transition: all 0.25s linear;
	overflow: hidden;
	&:first-of-type {
		width: 100%;
		flex: 0 1 50%;
		p {
			opacity: 1;
		}
	}
	&-1 {
		position: relative;
		&:before {
			content: url('../images/ptn.svg');
			position: absolute;
			top: 60px;
			left: 50%;
			transform: translateX(-50%);
			opacity: .3;
		}
	}
	&-2 {
		position: relative;
		&:before {
			content: url('../images/ptn.svg');
			position: absolute;
			top: 90px;
			right: 30px;
			opacity: .3;
		}
	}
	span {
		display: inline-block;
		width: 100%;
		margin-bottom: 230px;
		font-family: Bebas Neue;
		font-weight: bold;
		font-size: 32px;
		line-height: 130%;
		text-transform: uppercase;
		color: #FFFFFF;
		opacity: 0.6;
	}
	strong {
		display: inline-block;
		width: 100%;
		font-family: Bebas Neue;
		font-weight: bold;
		font-size: 48px;
		line-height: 120%;
		text-transform: uppercase;
		color: #FFFFFF;
		margin-bottom: 20px;
	}
	p {
		margin: 0;
		font-size: 20px;
		line-height: 1.4;
		color: #fff;
		min-height: 56px;
		opacity: 0;
		min-width: 300px;
		a {
			color: #fff;
			border-bottom: 1px solid rgba(255,255,255,.4);
			&:hover {
				border-bottom-color: transparent;
			}
		}
	}
}

.optbanner {
	background: url('../images/opt-bg2.jpg') center / cover no-repeat;
	position: relative;
	height: 460px;
	margin-bottom: 20px;
	&-item {
		position: relative;
		height: 180px;
		bottom: -350px;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background: #f2f2f2;
		border-right: 1px solid #e8e8e8;
		cursor: pointer;
		&:hover {
			background: $red;
			p {
				color: #fff;
			}
			img {
				filter: brightness(100);
			}
		}
		.image {
			height: 80px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;
			justify-content: center;
			img {}
		}
		p {
			margin: 0;
			font-weight: bold;
			font-size: 16px;
			line-height: 26px;
			text-align: center;
			color: #212121;
		}
	}
}

.review {
	padding: 90px 0 120px;
	background: #0077C0 url('../images/rev-ptn.png') 40px 70px no-repeat;
	.title {
		margin-bottom: 140px;
		h2 {
			color: #fff;
		}
	}
	.pagination {
		a {
			filter: brightness(10);
			color: #fff;
		}
	}
	&-items {
		h3 {
			font-size: 46px;
			color: #fff;
			margin: 0;
		}
		img {
			margin: 0 0 15px;
			display: inline-block !important;
		}
		strong {
			font-size: 15px;
			display: inline-block;
			width: 100%;
			margin: 0 0 8px;
			color: #fff;
			line-height: 1;
		}
		p {
			color: #fff;
			margin: 0;
			line-height: 1;
			font-size: 13px;
		}
	}
	.pagination .dots .slick-dots li {
		button {
			&:before {
				background: #fff;
			}
		}
		&.slick-active {
			button {
				border-color: #fff;
			}
		}
	}
}

.partners {
	&-wrapper {
		margin-top: 30px;
		margin-bottom: 80px;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	&-item {
		flex: 0 0 20%;
		width: 20%;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 110px;
		margin-bottom: 15px;
		transition: all .2s linear;
		&:nth-of-type(n+6) {
			display: none;
		}
		img {
			max-width: 90%;
			filter: grayscale(10);
		}
		&:hover {
			img {
				filter: none;
			}
		}
	}
	&-count {
		color: $red;
		margin: 0;
		font-family: Bebas Neue;
		font-weight: bold;
		font-size: 28px;
		line-height: 31px;
		letter-spacing: 0.03em;
	}
}

.calc {
	background: url('../images/calc-bg.jpg') center / cover no-repeat;
	padding: 90px 0 100px;
	color: #fff;
	.title {
		h3 {
			color: #fff;
		}
	}
	form {
		max-width: 360px;
	}
	.result {
		margin-top: 50px;
		p {
			font-weight: bold;
			font-size: 22px;
			margin-bottom: 15px;
			line-height: 150%;
			color: #FFFFFF;
		}
		strong {
			font-family: Bebas Neue;
			font-weight: bold;
			font-size: 48px;
			line-height: 120%;
			text-transform: uppercase;
			color: #FFFFFF;
		}
	}
}

dl {
	counter-reset: num;
	columns: 2;
	margin-bottom: 70px;
	&.column-1 {
		columns: 1;
		margin-bottom: 30px;
	}
	dd {
		font-size: 16px;
		width: 80%;
		line-height: 26px;
		color: #212121;
		padding-bottom: 15px;
		margin-bottom: 30px;
		border-bottom: 1px solid #e9e9e9;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
		display: inline-block;
		&:before {
			content: counter(num);
			counter-increment: num;
			display: inline-block;
			width: 100%;
			font-size: 10px;
			line-height: 13px;
			letter-spacing: 0.01em;
			color: #EE3224;
		}
	}
}

footer {
	background: #212121;
	padding: 60px 0 0;
	.logo {
		a {}
	}
	strong {
		display: inline-block;
		width: 100%;
		margin: 0 0 35px;
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
		text-transform: uppercase;
		color: #FFFFFF;
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			margin-bottom: 3px;
		}
		a {
			display: inline-block;
			font-size: 14px;
			line-height: 18px;
			color: rgba(255, 255, 255, 0.5);
			&:hover {
				color: $red;
			}
		}
	}
	.social {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			display: inline-block;
			margin-right: 15px;
		}
		a {
			font-size: 16px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 44px;
			height: 44px;
			border: 1px solid rgba(255, 255, 255, 0.15);
			border-radius: 40px;
			color: #fff;
			&:hover {
				color: #fff;
				background: $red;
				border-color: $red;
			}
		}
	}
	a {
		&.phone {
			font-size: 20px;
			line-height: 24px;
			text-transform: uppercase;
			margin: 0 0 15px;
			display: inline-block;
			color: #FFFFFF;
			&:hover {
				color: $red;
			}
		}
		&.btn {
			padding: 0 20px;
			line-height: 54px;
			&:hover {}
		}
		&.app {
			margin-bottom: 15px;
			display: inline-block;
			img {}
		}
	}
	.bottom_line {
		margin-top: 110px;
		border-top: 1px solid rgba(255,255,255,.2);
		line-height: 40px;
		a {
			font-size: 12px;
			line-height: 40px;
			margin: 0;
			color: rgba(255,255,255,.5);
			display: inline-block;
			&:hover {
				color: $red;
			}
		}
		p {
			font-size: 12px;
			line-height: 40px;
			margin: 0;
			color: rgba(255,255,255,.5);
		}
	}
}

.d-mobile {
	display: none;
}

nav {
	position: fixed;
	left: 0;
	width: 100%;
	top: 0;
	height: 100%;
	background: #212121;
	z-index: 99;
	color: #fff;
	padding: 30px 25px;
	max-width: 480px;
	overflow: auto;
	display: none;
	.nav {
		&-header {
			margin-bottom: 40px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.logo {}
			.close {
				font-size: 40px;
				font-weight: 100;
				opacity: 1;
				position: static;
				color: #fff;
				text-shadow: none;
				margin: 0;
				padding: 0;
			}
		}
		&-menu {
			margin-bottom: 70px;
			padding-left: 80px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			& > a, strong {
				display: inline-block;
				line-height: 1;
				margin-bottom: 20px;
				font-weight: bold;
				font-size: 14px;
				line-height: 18px;
				text-transform: uppercase;
				color: #FFFFFF;
			}
			strong {
				background: url('../images/dd.svg') right center no-repeat;
				padding-right: 30px;
				&.active {
					background: url('../images/du.svg') right center no-repeat;
					& + ul {
						display: inline-block;
					}
				}
			}
			ul {
				list-style: none;
				padding: 0;
				margin: -5px 0 30px;
				display: none;
				li {
					margin: 0 0 5px;
					a {
						font-size: 14px;
						font-weight: 400;
						text-transform: none;
						line-height: 2;
					}
				}
			}
		}
		&-contacts {
			padding-left: 80px;
			.city {
				color: #fff;
				font-size: 18px;
				margin: 0 0 20px;
				display: inline-block;
				select {
					padding: 0;
					font-size: 16px;
					margin: 0;
				}
			}
			.phone {
				margin: 0 0 60px;
				a {
					font-size: 24px;
				}
			}
			.btn {
				margin: 0 0 60px;
				font-size: 13px;
			}
			.lang {
				text-align: left;
				justify-content: flex-start;
				margin: 0;
				a {
					font-weight: 500;
					font-size: 16px;
					margin: 0 20px 0 0;
				}
			}
		}
	}
	.selectize {
		&-control {
			// max-width: 50%;
		}
	}
}

.selectize {
	&-control {
		// width: 180px;
		cursor: pointer;
	}
	&-input {
		border: none;
		padding: 0;
		background: none !important;
		display: -webkit-flex !important;
		display: -moz-flex !important;
		display: -ms-flex !important;
		display: -o-flex !important;
		display: flex !important;
		align-items: center;
		flex-wrap: nowrap;
		white-space: nowrap;
		box-shadow: none !important;
		&.focus {
			&:after {
				border-bottom-color: #fff !important;
			}
		}
		&:after {
			margin-top: -1px !important;
			border-top-color: #fff !important;
			right: 0 !important;
		}
		.item {
			color: #fff;
			padding-right: 40px;
		}
		input {
			display: none !important;
		}
	}
	&-dropdown {
		min-width: 180px;
		&-content {
			.option {
				cursor: pointer;
				padding: 5px 10px;
				font-size: 13px;
				white-space: nowrap;
			}
		}
	}
}

.modal {
	&-content {
		border: none;
		border-radius: 0;
		max-width: 460px;
		width: 100%;
		margin: 0 auto;
	}
	&-body {
		padding: 40px 60px 60px 40px;
		&:before {
			content: url('../images/fish.png');
			position: absolute;
			right: -100px;
			bottom: 70px;
			z-index: 99;
		}
	}
	.close {
		position: absolute;
		top: 10px;
		right: 15px;
		opacity: 1;
		font-size: 36px;
		font-weight: 100;
		color: #212121;
		z-index: 999;
		outline: none !important;
	}
	.form {
		padding: 0;
		h2 {
			color: #000;
			font-size: 42px;
			line-height: 1;
			margin-bottom: 10px;
			& + p {
				font-size: 16px;
				line-height: 26px;
				color: #212121;
				margin-bottom: 40px;
			}
		}
		.form {
			&-group {
				position: relative;
				label {
					position: absolute;
					left: 0;
					top: 40%;
					font-size: 16px;
					color: #212121;
					transition: all .3s linear;
					&.active {
						font-size: 11px;
						top: 15%;
						opacity: .5;
					}
				}
			}
			&-control {
				height: 56px;
				background: none;
				border-radius: 0;
				box-shadow: none !important;
				border: none;
				padding: 15px 0 0;
				font-weight: 500;
				color: #212121;
				border-bottom: 1px solid rgba(0,0,0,.5);
				&:focus {
					border-bottom-color: #212121;
					& + label {
						font-size: 11px;
						top: 15%;
						opacity: .5;
					}
				}
			}
		}
		.btn {
			line-height: 56px;
			padding: 0 50px;
			margin-top: 10px;
			margin-bottom: 15px;
		}
			p {
				font-size: 13px;
				line-height: 20px;
				margin: 0;
				color: rgba(0,0,0,.5);
				a {
					color: rgba(0,0,0,.5);
					text-decoration: underline;
					&:hover {
						color: $red;
					}
				}
			}
	}
}

#scheme {
	.modal-dialog {}
	.modal-content {
		max-width: unset;
	}
	.modal-content .close {
		text-shadow: none;
		color: #fff;
		opacity: 1;
		top: 25px;
		right: 40px;
	}
	.modal-body {
		padding: 0;
		&:before {
			display: none;
		}
		a {
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 23px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #FFFFFF;
			padding: 0 40px 0 90px;
			display: inline-block;
			width: 100%;
			line-height: 90px;
			background: #0077C0 url('../images/dnld.svg') 40px center no-repeat;
			&:hover {
				text-decoration: underline;
			}
		}
		img {
			width: 100%;
		}
	}
}

.person__line {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #b3b3b3;
  .person__line-img {
    display: block;
    height: 58px;
    width: 100%;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    filter: grayscale(100%) brightness(100%) contrast(40%);
  }
  &_item {
    width: 6.25%;
    height: 58px;
    float: left;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    &:hover .person__line-img {
		  filter: none;
		}
		&:hover {
		  z-index: 9;
		  overflow: unset;
		  text-decoration: none !important;
		  -webkit-transform: scale(1.5, 1.5);
		  -ms-transform: scale(1.5, 1.5);
		  -o-transform: scale(1.5, 1.5);
		  transform: scale(1.5, 1.5);
		  .hover {
		    display: block;
		    background: #000 url('../images/ptn.5.svg') right center / contain no-repeat;
		  }
		}
		.hover {
		  position: absolute;
		  left: 0;
		  right: 0;
		  top: 100%;
		  display: none;
		  padding: 5px 10px 15px;
		  background: #000;
		  strong {
		    color: #fff;
		    font-size: 7px;
		    text-transform: uppercase;
		    line-height: 1.2;
		    display: inline-block;
		    margin-bottom: 5px;
		  }
		  p {
		    color: #fff;
		    font-size: 8px;
		    color: rgba(255, 255, 255, 0.5);
		    line-height: 1;
		    margin: 0;
		  }
		}
  }
}

#maps, #maps2 {
	iframe {
		margin: 0 0 -200px -30px
	}
}

.contacts {
	&-line {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			font-weight: bold;
			font-size: 14px;
			line-height: 18px;
			color: #212121;
			text-transform: uppercase;
			margin: 0;
			span {
				color: $red;
			}
		}
	}
	&-item {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid rgba(0,0,0,.1);
		strong {
			font-family: Bebas Neue;
			font-weight: bold;
			font-size: 30px;
			line-height: 26px;
			color: #212121;
			display: inline-block;
			margin: 0 0 18px;
		}
		p {
			font-size: 13px;
			line-height: 20px;
			color: #212121;
			margin: 0 0 5px;
		}
		span {
			display: inline-block;
			margin-bottom: 10px;
			font-family: Bebas Neue;
			font-style: normal;
			font-weight: normal;
			font-size: 22px;
			line-height: 26px;
			letter-spacing: 0.03em;
			color: #212121;
			width: 100%;
		}
		a {
			font-family: Bebas Neue;
			font-style: normal;
			font-weight: normal;
			font-size: 22px;
			line-height: 26px;
			letter-spacing: 0.03em;
			color: $red;
			&.modal__link {
				font-family: IBM Plex Sans;
				font-weight: bold;
				font-size: 14px;
				line-height: 18px;
				letter-spacing: 0.05em;
				text-transform: uppercase;
				color: #EE3224;
			}
		}
	}
}

.manager {
	&-items {
		margin-top: 20px;
		.image {
			width: 120px;
			height: 120px;
			border-radius: 120px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 30px;
			img {}
		}
		strong {
			display: inline-block;
			width: 100%;
			font-family: Bebas Neue;
			font-weight: bold;
			font-size: 32px;
			line-height: 120%;
			letter-spacing: 0.01em;
			text-transform: uppercase;
			color: #212121;
			margin-bottom: 5px;
		}
		p {
			font-size: 16px;
			line-height: 26px;
			color: #212121;
			min-height: 80px;
			margin: 0 0 30px;
			width: 50%;
		}
		.contacts {
			padding-bottom: 30px;
			display: flex;
			flex-direction: column;
			position: relative;
			&:before {
				content: '';
				width: 130px;
				height: 2px;
				background: $red;
				position: absolute;
				bottom: 0;
				left: 0;
			}
			a {
				font-size: 13px;
				line-height: 20px;
				color: #212121;
				display: inline-block;
			}
			.phone {
				font-family: Bebas Neue;
				font-weight: bold;
				font-size: 22px;
				line-height: 150%;
				letter-spacing: 0.05em;
			}
		}
	}
}

.deco {
	&:before {
		content: '';
		position: absolute;
		display: inline-block;
		width: 100px;
		height: 100px;
	}
	&-1 {
		&:before {
			background: url('../images/d1.png') center no-repeat;
			left: -120px;
			top: 300px;
		}
	}
	&-2 {
		&:before {
			background: url('../images/d2.png') center no-repeat;
			bottom: 0;
			right: -200px;
		}
	}
}

@import "media.scss";