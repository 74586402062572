@media(max-width: 1700px) {
	header {
		.phone a {
			font-size: 21px;
		}
	}
	.deco:before {
		display: none;
	}
}
@media(max-width: 1550px) {
	header,footer {
		.container-padding {
			max-width: 1170px;
		}
	}
	footer {
		.col-lg-2 {
			flex: 0 0 25%;
			width: 25%;
			max-width: 25%;
		}
	}
	.d-desktop {
		display: none;
	}
	.d-mobile {
		display: inline-block;
	}
	.menu-mobile {
		background: url('../images/menu.svg') center no-repeat;
		width: 54px;
		height: 54px;
		display: inline-block;
	}
	.link_social-xl {
		font-size: 36px;
		height: 260px;
	}
}

@media(max-width: 1199px) {
	.header {
		padding: 70px 0 85px;
		position: relative;
		.before-left {
			&:before {
				bottom: 40px !important;
			}
		}
		h1 {
			font-family: Bebas Neue;
			font-weight: bold;
			font-size: 70px;
			line-height: 1;
			letter-spacing: 0.03em;
			color: #FFFFFF;
			margin-bottom: 20px;
		}
		p {
			font-size: 20px;
			line-height: 150%;
			letter-spacing: -0.005em;
			color: #FFFFFF;
			margin-bottom: 35px;
		}
		&-inner {
			padding-bottom: 80px;
			.before-center {
				&:before {
					bottom: -86px;
				}
			} 
		}
	}
	.box {
		padding: 90px 0;
	}
	.about {
		padding: 100px 0 50px;
		.before-left {
			&:before {
				bottom: -50px;
			}
		}
	}
	.news-item {
		a {
			padding-bottom: 140px;
		}
		&-xl {
			img {
				max-width: 100%;
			}
		}
	}
	.bg_link {
		height: 200px;
		line-height: 200px;
		background-size: 80% !important;
		font-size: 38px;
		span {
			font-size: 18px;
		}
	}
	.title {
		margin: 0 0 50px;
		h1, .h1 {
			font-size: 68px;
		}
		h2, h3, .h2, .h3 {
			font-size: 46px;
			line-height: 46px;
		}
		span {
			font-size: 16px;
		}
	}

	h2, .h2 {
		font-size: 46px;
		line-height: 46px;
		margin: 0 0 30px;
	}

	h3, .h3 {
		font-size: 36px;
		line-height: 36px;
		margin: 0 0 30px;
	}

	h4, .h4 {
		font-size: 22px;
		line-height: 150%;
		margin-bottom: 30px;
	}
	.review-items {
		h3 {
			font-size: 34px;
			line-height: 1.3;
		}
	}
	.person__line {
	  &_item {
	  	width: 12.5%;
	  }
	}
	.opt-item {
		strong {
			font-size: 36px;
		}
	}
}

@media(max-width: 991px) {
	.opt-wrapper {
		flex-direction: column;
		margin: 0 0 40px;
	}
	.opt-item {
		margin: 0 0 20px;
		width: 100%;
		flex: 0 0 100% !important;
		p {
			opacity: 1 !important;
		}
		span {
			margin-bottom: 40px;
		}
	}
	dl {
		columns: 1;
		dd {
			width: 100%;
		}
	}
	footer {
		.col-lg-2 {
			flex: 0 0 33.3333333%;
			width: 33.3333333%;
			max-width: 33.3333333%;
		}
	}
	.link_social-xl {
		width: 33.333333%;
		flex: 0 0 33.333333%;
	}
	.bg_link {
		height: 155px;
		line-height: 155px;
	}
	.padding-left {
	    padding-left: calc((100% - 690px)/ 2);
	}
	.services-item .image {
		height: 220px;
	}
	.form {
		padding-top: 80px;
	}
	.about {
		padding-bottom: 100px;
		background: #212121 url(../images/about-bg-md.jpg) bottom center /contain no-repeat;
		.counts-item {
			width: 100%;
			justify-content: flex-start;
			margin-top: 290px;
		}
		.before-left {
			&:before {
				bottom: -100px;
			}
		}
	}
	.command-item h3 {
		font-size: 36px;
		line-height: 1;
	}
	.partners-item {
		flex: 0 0 20%;
		text-align: center;
		width: 20%;
		img {
			max-width: 75%;
		}
	}
	.order-1 {
		order: 1;
		margin-bottom: 30px;
	}
	.order-2 {
		order: 2;
	}
	.nav-tabs .nav-item .nav-link {
		display: inline-block;
		min-height: 64px;
	}
}

@media(max-width: 767px) {
	.slick-services {
		width: 100%;
	}
	.nav-tabs .nav-item {
		margin: 0 30px 0 0;
		.nav-link {
			min-height: auto;
			padding-bottom: 10px;
		}
	}
	.contacts-line {
		flex-direction: column;
		align-items: flex-start;
		p {
			margin: 0 0 15px;
			font-size: 16px;
			font-weight: 500;
			line-height: 1.5;
		}
	}
	.nav-pills {
		margin: 15px 0 0;
		li {
			margin: 0 10px 0 0;
		}
	}
	#maps, #maps2 {
		iframe {
			height: 480px;
			margin: 0 0 30px;
		}
	}
	.advantages-item .text 	{
		h4 {
			font-size: 18px;
			& + p {
				font-size: 14px;
			}
		}
	}
	.optbanner {
		height: auto;
		padding: 50px 15px;
	}
	.optbanner-item {
		position: static;
		border: none;
		border-bottom: 1px solid #e8e8e8;
	}
	.opt-item {
		strong {
			font-size: 24px;
			br {
				display: none;
			}
		}
		p {
			min-height: auto;
			font-size: 14px;
		}
	}
	.person__line {
	  &_item {
	  	width: 25%;
	  }
	}
	main .app {
		background: #0077c0;
		.breadcrumbs {
			margin-bottom: 40px;
		}
		p {
			font-size: 16px;
			& + ul {
				flex-direction: column;
				align-items: flex-start;
				li {
					margin: 0 0 10px;
				}
			}
		}
	}
	.title {
		margin: 0 0 50px;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		margin-bottom: 30px;
		h1, .h1 {
			font-size: 54px;
			margin-bottom: 30px;
		}
		h2, h3, .h2, .h3 {
			font-size: 42px;
			line-height: 42px;
			margin: 0;
		}
		span {
			font-size: 14px;
			width: 100%;
			max-width: 100%;
			margin-top: 20px;

		}
	}

	h2, .h2 {
		font-size: 32px;
		line-height: 32px;
		margin: 0 0 30px;
	}

	h3, .h3 {
		font-size: 26px;
		line-height: 26px;
		margin: 0 0 30px;
	}

	h4, .h4 {
		font-size: 18px;
		line-height: 150%;
		margin-bottom: 30px;
	}
	.header-inner {
		padding-bottom: 30px;
		.before-center:before {
			bottom: -37px;
		}
	}
	.box-404 p {
		font-size: 16px;
	}
	.bg_link {
		height: 115px;
		line-height: 115px;
		font-size: 21px;
		span {
			font-size: 14px;
		}
	}
	footer .col-lg-2 {
		flex: 0 0 100%;
		width: 10%;
		max-width: 100%;
		text-align: center;
		margin-bottom: 35px;
		strong {
			margin: 15px 0 20px;
			padding: 0 !important;
			font-size: 16px;
		}
		.social {
			li {
				margin: 0 8px;
			}
		}
		a.app {
			margin: 0 7px;
			padding: 0 !important;
		}
	}
	footer {
		.bottom_line {
			margin-top: 50px;
		}
	}

	.news-item-xl {
		img {
			width: 100%;
			margin-bottom: 50px;
		}
		span {
			margin-bottom: 10px;
		}
		h2 {
			margin-bottom: 30px;
		}
		a {}
	}
	.nav {
		&-tabs {
			white-space: nowrap;
			overflow: auto;
		}
	}
	.box{
		padding: 60px 0;
	}
	.command {
		padding-top: 80px;
		.slick-dotted.slick-slider {
			margin: 0;
		}
		.pagination {
			margin-top: 0;
		}
		h2 {

			margin: 0 0 25px;
			br {
					display: none;
			}
		}
	}
	.files-item {
		margin-bottom: 20px;
	}
	.review {
		padding: 80px 0 70px;
		.title {
			margin-bottom: 60px;
		}
		h3 {
			font-size: 26px;
		}
		img {
			margin: 0 20px 0 0;
		}
		.rev-sm-left {
			text-align: left !important;
			margin-top: 20px;
			display: flex;
			align-items: center;
		}
	}
	.partners-wrapper {
		flex-wrap: wrap;
		justify-content: center;
	}
	.partners-item {
		flex: 0 0 33.333333%;
		width: 33.333333%;
		margin: 15px 0 0;
	}
	.padding-left {
		padding-left: calc((100% - 510px)/ 2);
	}
	.about .counts-item {
		margin-top: 70px;
		strong {
			font-size: 44px;
		}
		p {
			margin-left: 15px;
		}
	}
	.advantages-item {
		margin-bottom: 30px;
		.text {
			strong {
				font-size: 28px;
			}
		}
		.image {
			width: 70px;
			height: 70px;
			flex: 0 0 70px;
			margin-right: 10px;
			img {
				max-width: 40px;
				max-height: 40px;
			}
		}
	}
}

@media(max-width: 575px) {
	header.main {
		height: auto;
		min-height: auto;
		.header .before-left {
			h1 {
				br {
					display: none;
				}
			}
			p {
				width: 80%;
			}
			.slick-dots {
				li {
					width: 5px;
					height: 5px;
					margin: 10px 0;
					opacity: 0.5;
					&.slick-active {
						width: 10px;
						height: 10px;
						opacity: 1;
						button {
							width: 10px;
							height: 10px;
							background: #fff;
							border-radius: 10px;
						}
					}
					button {
						width: 5px;
						height: 5px;
						padding: 0;
						background: #fff;
						border-radius: 10px;
						&:before {
							display: none;
						}
					}
				}
			}
			&:before {
				bottom: -85px !important;
			}
		}
	}
	.nav-pills {
		margin: 15px 0 0;
		flex-wrap: wrap;
		li {
			width: 100%;
			margin: 0 0 10px;
		}
	}
	#maps, #maps2 {
		iframe {
			height: 240px;
			margin: 0 0 30px;
		}
	}
	.person__line {
	  display: none;
	}
	.bg_link {
		display: none !important;
	}
	.btn {
		line-height: 50px;
		font-size: 13px;
		padding: 0 25px;
	}
	.pagination {
		.dots {
			display: none;
		}
	}
	.before {
		&-left {
			&:before {
				width: 100%;
				overflow: hidden;
				left: 50% !important;
				transform: translateX(-50%) !important;
			}
		}
		&-center {
			&:before {
				width: 100%;
				overflow: hidden;
				left: 50% !important;
				transform: translateX(-50%) !important;
			}
		}
	}
	.header {
		
		&__video {
			display: none;
		}
		h1 {
				font-size: 36px;
			}
			p {
				font-size: 14px;
				br {
					display: none;
				}
			}
	}
	.form {
		p {
			font-size: 14px;
			line-height: 1.4;
		}
	}
	.about {
		background: #212121;
		.btn {
			margin-bottom: 40px;
		}
	}
	.about .counts-item {
		margin: 0px 0;
	}
	.link_social-xl {
		flex: 0 0 100%;
	}
	.padding-left {
		padding: 0 15px;
	}
	.box-404 {
		background: url(../images/err-bg-sm.jpg) center 30px /485px no-repeat;
		padding-top: 280px;
		text-align: center;
		p {
			br {
				display: none;
			}
		}
	}
	.command-item p {
		font-size: 14px;
		line-height: 1.3;
	}
	.title .h1, .title h1 {
		font-size: 46px;
	}
	.tanders-item {
		margin-bottom: 40px;
		p {
			font-size: 16px;
		}
	}
	footer .container-padding, header .container-padding {
		padding: 30px 15px;
	}
	footer {
		text-align: center;
		padding-top: 40px;
		a.app {
			margin-bottom: 7px !important;
		}
		.bottom_line {
			padding: 14px 0;
			line-height: 1;
			margin-top: 30px;
			a {
				line-height: 26px;
			}
			.container-padding {
				padding: 0 15px;
			}
		}
		.bottom_line p {
			text-align: center !important;
			line-height: 26px;
		}
	}
}